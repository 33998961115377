import React, {Fragment, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {v4} from "uuid";
import Websocket from 'react-websocket';
import {updateCSHost, updateWsConnected, updateWsReceive} from "../../appRedux/actions";
import {useAuth} from "../../authentication";


const WS = () => {

  const {subUser} = useAuth();

  // const [preWsSend, setPreWsSend] = useState(null);
  const refPrevWsSend = useRef(null);
  const receiveQueueRef = useRef([]);
  const [receivedCount, setReceivedCount] = useState(0)
  const [accessInfo, setAccessInfo] = useState(undefined);
  const refWebsocket = useRef(undefined);
  const dispatch = useDispatch();

  const wsSend = useSelector(({ws}) => ws.wsSend);
  const csSelected = useSelector(({cs}) => cs.csSelected);
  const csHost = useSelector(({cs}) => cs.csHost);

  useEffect(() => {

    return () => {
      refWebsocket && refWebsocket.current && refWebsocket.current.close && refWebsocket.current.close()
    };
  }, [])

  useEffect(() => {

  }, [csSelected]);

  useEffect(() => {
    // console.log('SWS-DEBUG-wsSend', wsSend, preWsSend, refWebsocket.current)
    if (
      wsSend &&
      wsSend.data &&
      wsSend !== refPrevWsSend.current &&
      refWebsocket && refWebsocket.current && refWebsocket.current.state.ws.readyState === 1
    ) {
      sendWS(wsSend)
    }
  }, [wsSend])

  useEffect(() => {
    if (receiveQueueRef.current.length > 0) {
      setReceivedCount((receivedCount + 1)% 10)
      const m = receiveQueueRef.current.pop()
      dispatch(updateWsReceive(m))
    }
  }, [receivedCount]);

  const onopenWS = (e) => {

    dispatch(updateWsConnected(true))

  }

  const oncloseWS = (e) => {
    dispatch(updateWsConnected(false))
    dispatch(updateCSHost(undefined))
  }

  const onmessageWS = (e) => {
    if (e) {
      let s = e.split(/\r?\n/);
      for (let i = 0; i < s.length; i++) {
        // console.log('onmessageWS', s[i])
        let payload = JSON.parse(s[i]);
        if (payload.data && payload.data.category === 'ws') {
          receiveQueueRef.current.push(payload.data)
          setReceivedCount((receivedCount + 1) % 10)
        }
      }
    }
  }

  const sendWS = (msg) => {

    // TODO: set device access token from Redux
    const values = {
      data: {
        ...msg.data,
      }
    }
    refWebsocket.current && refWebsocket.current.sendMessage(JSON.stringify(values));
    refPrevWsSend.current = values

    // let access_info = JSON.parse(localStorage.getItem('access_info'));
    // console.log('SWS-DEBUG-accessInfo', accessInfo, msg)
    // if (accessInfo) {
    //   const values = {
    //     data: {
    //       ...msg.data,
    //       access_token: accessInfo.access_token,
    //       device_access_token: accessInfo.device_access_token,
    //     }
    //   }
    //   refWebsocket.current && refWebsocket.current.sendMessage(JSON.stringify(values));
    //   refPrevWsSend.current = values
    // }
  };

  return (
    <Fragment>
      {subUser && csHost && csSelected.websocket_server_url && csSelected.websocket_server_url.length ?
        <Websocket url={csSelected.websocket_server_url}
                   onOpen={onopenWS}
                   onMessage={onmessageWS}
                   onClose={oncloseWS}
                   // reconnectIntervalInMilliSeconds={1000}
                   ref={websocket => {
                     refWebsocket.current = websocket;
                   }}/> : null}
    </Fragment>
  )
};

export default WS;

// React-SVG로 바닥부터 구현하는 간단한 가이드
import React, {useEffect, useRef, useState} from 'react';
import Box from "../Box";
import FMB from "../FMB";
import beApis from "../../../../../util/apis/be";
import {useDispatch, useSelector} from "react-redux";
import {updateForceLoading} from "../../../../../appRedux/actions";

const Grid = ({
                groupName,
                tab,
                agents,
                viewBoxWidth = 1000,
                viewBoxHeight = 1000,
                width,
                height,
                BOX_HEIGHT = 8,
                BOX_WIDTH = 14,
                gridSize = 20,
                onClickConnect,
                onClickSetting,
                onClickAgentItem,
              }) => {
  const dispatch = useDispatch();

  const [draggingBoxIndex, setDraggingBoxIndex] = useState(null);
  const [dragOffset, setDragOffset] = useState({x: 0, y: 0});
  const [updatedBoxes, setUpdatedBoxes] = useState([]);
  const [reservedBoxes, setReservedBoxes] = useState([]);
  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const validPressed = useRef(false);

  const csSelected = useSelector(({cs}) => cs.csSelected);

  useEffect(() => {

    if (agents) {
      const boxes = []
      const reserved = []

      agents.forEach((agent) => {
        let isFound = false
        for (let i = 0; i < agent.groups.length; i++) {
          if (agent.groups[i].name === groupName) {

            // console.log(`agents[${i}] groups`, groupName, agent.groups[i])

            const x = agent.groups[i].x * gridSize
            const y = agent.groups[i].y * gridSize
            boxes.push({
              ...agent,
              lastX: x,
              lastY: y,
              x: x,
              y: y,
            });
            isFound = true
            break
          }
        }
        if (!isFound) {
          reserved.push({...agent})
        }
      })

      setUpdatedBoxes(boxes)
      setReservedBoxes(reserved)
    }
  }, [groupName, agents]);

  // 모눈종이 스타일을 위해 격자 배경을 그리는 SVG
  const renderGridBackground = (width, height) => {
    const gridLines = [];

    for (let x = 0; x < width; x += gridSize) {
      gridLines.push(<line key={`v-${x}`} x1={x} y1={0} x2={x} y2={height} stroke="#DCDCDC"/>);
    }

    for (let y = 0; y < height; y += gridSize) {
      gridLines.push(<line key={`h-${y}`} x1={0} y1={y} x2={width} y2={y} stroke="#DCDCDC"/>);
    }

    return gridLines;
  };

  const handleMouseDown = (e, index) => {

    // console.log('handleMouseDown', index, e.target.ownerSVGElement?.id, e.target.ownerSVGElement)

    if (e.target.ownerSVGElement?.id !== 'boxes') {
      return
    }

    e.stopPropagation();

    const clientX = e.clientX || (e.touches && e.touches[0]?.clientX);
    const clientY = e.clientY || (e.touches && e.touches[0]?.clientY);

    validPressed.current = true;

    if (clientX != null && clientY != null) {
      // 길게 누르기를 위한 타이머 설정
      const timeout = setTimeout(() => {
        if (!validPressed.current) {
          return
        }

        const svgRect = e.target.ownerSVGElement.getBoundingClientRect();
        setDraggingBoxIndex(index);
        setDragOffset({
          x: clientX - svgRect.left - ((updatedBoxes[index].x / viewBoxWidth) * svgRect.width),
          y: clientY - svgRect.top - ((updatedBoxes[index].y / viewBoxHeight) * svgRect.height),
        });
      }, 1000); // 500ms 후에 드래그 시작
      setLongPressTimeout(timeout);
    }
  };

  const handleMouseUp = () => {

    if (draggingBoxIndex !== -1 && draggingBoxIndex < updatedBoxes.length && updatedBoxes[draggingBoxIndex]) {
      const b = updatedBoxes[draggingBoxIndex]
      const newX = Math.round(b.x / gridSize)
      const newY = Math.round(b.y / gridSize)

      if (b.lastX !== b.x || b.lastY !== b.y) {

        setUpdatedBoxes((prevBoxes) =>
          prevBoxes.map((box, index) =>
            index === draggingBoxIndex ? {...box, lastX: b.x, lastY: b.y} : box
          )
        );

        beApis.UpdateAgentGroup(csSelected.host, {
          device_access_token: b.device_access_token,
          group: {
            name: groupName,
            x: newX,
            y: newY,
          }
        }).then(resp => {

          dispatch(updateForceLoading({agents: true}))
        })
      }
    }

    if (longPressTimeout) {
      clearTimeout(longPressTimeout); // 길게 누르기 타이머 해제
      setLongPressTimeout(null);
    }
    setDraggingBoxIndex(null);
  };

  const handleMouseMove = (e) => {
    const container = e.currentTarget.parentElement;
    const containerRect = container.getBoundingClientRect();
    const bottomBoundary = containerRect.bottom;
    const clientY = e.clientY || (e.touches && e.touches[0]?.clientY);
    if (clientY != null) {
      if (clientY > bottomBoundary - 50) {
        container.scrollTop += 10;
      } else if (clientY < containerRect.top + 50) {
        container.scrollTop -= 10;
      }
    }
    const clientX = e.clientX || (e.touches && e.touches[0]?.clientX);

    const svgRect = e.currentTarget.getBoundingClientRect();
    let newX = ((clientX - svgRect.left - dragOffset.x) / svgRect.width) * viewBoxWidth;
    let newY = ((clientY - svgRect.top - dragOffset.y) / svgRect.height) * viewBoxHeight;

    // 격자에 맞추어 위치를 조정하여 sticky하게 움직이도록 설정
    newX = Math.round(newX / gridSize) * gridSize;
    newY = Math.round(newY / gridSize) * gridSize;

    // 경계선을 넘어가지 않도록 제한
    const boxWidth = gridSize * BOX_WIDTH;
    const boxHeight = gridSize * BOX_HEIGHT;
    newX = Math.max(0, Math.min(newX, viewBoxWidth - boxWidth));
    newY = Math.max(0, Math.min(newY, viewBoxHeight - boxHeight));

    // 다른 박스와 겹치지 않도록 제한
    const isOverlapping = (x, y) => {
      return updatedBoxes.some((box, index) => {
        if (index === draggingBoxIndex) return false;
        const boxX = box.x;
        const boxY = box.y;
        return (
          x < boxX + boxWidth &&
          x + boxWidth > boxX &&
          y < boxY + boxHeight &&
          y + boxHeight > boxY
        );
      });
    };
    if (draggingBoxIndex !== null) {

      if (clientX != null && clientY != null) {

        if (!isOverlapping(newX, newY)) {
          setUpdatedBoxes((prevBoxes) =>
            prevBoxes.map((box, index) =>
              index === draggingBoxIndex ? {...box, x: newX, y: newY} : box
            )
          );
          // console.log(`Box ${draggingBoxIndex} moved to: x=${newX}, y=${newY}`);
        }
      }
    } else {
      if (!isOverlapping(newX, newY)) {
        validPressed.current = false;
      }
    }
  };

  const renderBoxes = () => {
    const boxWidth = gridSize * BOX_WIDTH;
    const boxHeight = gridSize * BOX_HEIGHT;

    return updatedBoxes.map((box, index) => (
      <Box
        key={`${width}${height}${index}`}
        box={box}
        index={index}
        gridSize={gridSize}
        handleMouseDown={handleMouseDown}
        handleMouseUp={handleMouseUp}
        boxWidth={boxWidth}
        boxHeight={boxHeight}
        style={draggingBoxIndex === index ? {
          zIndex: 4,
          strokeWidth: 1,
          filter: 'drop-shadow(0px 0px 5px #EFEFEF)',
        } : {
          zIndex: 3,
        }}
        onClickConnect={onClickConnect}
        onClickSetting={onClickSetting}
        // onClickSettingMenu={onClickAgentItem}
      />
    ));
  };

  return (
    <div style={{
      overflow: 'auto',
      height: 'calc(100vh - 300px)',
      // width: '1000px',
      // width: '100%',
      // maxWidth: 1000,
      // minWidth: 1000,
      minWidth: '1000px',
      // width: 'calc(100vw - 400px)',
    }}>
      <div
        style={{
          width: '100%',
          // minWidth: '1000px',
          // width: 1000,
          height: 'calc(100vh)',
          position: 'relative',
          cursor: draggingBoxIndex !== null ? 'grabbing' : 'default',
        }}
        onMouseMove={handleMouseMove}
        onTouchMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleMouseUp}
      >
        <svg
          id={`boxes`}
          width="100%"
          height="100%"
          viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
          preserveAspectRatio="none"
          style={{
            border: '1px solid #DCDCDC',
            borderRadius: '4px',
            backgroundColor: draggingBoxIndex !== null ? 'rgba(0, 0, 0, 0.25)' : '#EFEFEF',
          }}
        >
          {renderGridBackground(viewBoxWidth, viewBoxHeight)}
          {renderBoxes()}
        </svg>
      </div>
      <FMB width={width} height={height} onClickConnect={() => {
        onClickConnect && onClickConnect(undefined, 'add', tab);
      }}/>
    </div>
  );
};

export default Grid;

import {
  COMMON_FORCE_LOADING,
  CS_ACTIVE_AGENTS,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  SET_XDATA, COMMON_AGENT_VIEW,
} from "../../constants/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};

export const setXdata = (data) => {
  return {
    type: SET_XDATA,
    payload: data,
  }
}

export const updateAgentView = (data) => {
  return {
    type: COMMON_AGENT_VIEW,
    payload: data,
  }
}
export const updateForceLoading = (forceLoading) => {
  return (dispatch) => {
    dispatch({type: COMMON_FORCE_LOADING, forceLoading});
  }
};





import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import {Divider, Tabs, theme} from "antd";
import StickyBox from "react-sticky-box";
import {useIntl} from "react-intl";
import {getConstantLayoutAdd} from "../../util/common";
import './index.css';
import ArrowDown from "../../assets/svg/ArrowDown";
import Plus from "../../assets/svg/Plus";
import PcOn from "../../assets/svg/PcOn";
import IntlMessages from "../../util/IntlMessages";
import PcOff from "../../assets/svg/PcOff";
import VlinkLive from "../../assets/svg/VlinkLive";
import AgentCardView from "./AgentCardView";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import StyledTooltip from "components/StyledTooltip";
import StyledMenuOptions from "components/StyledMenuOptions";
import Edit from "assets/svg/Edit";
import Delete from "assets/svg/Delete";
import AtomicButton from "../../components/AtomicButton";
import CardViewActive from "../../assets/svg/CardViewActive";
import CardViewInactive from "../../assets/svg/CardViewInactive";
import LayoutViewActive from "../../assets/svg/LayoutViewActive";
import LayoutViewInactive from "../../assets/svg/LayoutViewInactive";
import * as PropTypes from "prop-types";
import AgentLayoutView from "./AgentLayoutView";
import {updateAgentView} from "../../appRedux/actions";

const ADD_LAYOUT_NAME = getConstantLayoutAdd()

const AgentTabs = (props) => {
  const intl = useIntl();

  const [activeKey, setActiveKey] = useState(0)
  const [tabs, setTabs] = useState([]);
  const [activeType, setActiveType] = useState('card')

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newTabIndex = useRef(0);

  const [visibleTooltip, setVisibleTooltip] = useState({})


  const {
    token: {
      colorPrimary
    },
  } = theme.useToken();

  useEffect(() => {
    setTabs(props.tabs)
  }, [props.tabs])

  useEffect(() => {
    dispatch(updateAgentView(activeType))
  }, [activeType]);

  const handleClickAgent = () => {
    setVisibleTooltip({});
  }
  const handleClickConnect = (agent, mode, tab) => {
    props.onClickConnect && props.onClickConnect(agent, mode, tab)
    // dispatch(updateAgentSelected(agent))
    // navigate(`/home/stream`);
  }

  const handleClickSetting = (item, option) => {
    // console.log("AgentTabls: handleClickSetting", item, option)
    props.onClickAgentSetting && props.onClickAgentSetting(item, option);
  }

  const handlePressTab = (index) => {
    if (index) {
      setVisibleTooltip({
        [index]: true,
      })

      setTabs(JSON.parse(JSON.stringify(props.tabs)));

    }
  }

  const AgentStatusComponent = memo(({icon, text, showDivider}) => (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      marginRight: 16,
    }}>
      {icon}
      <span style={{margin: '0 4px'}}>
        <IntlMessages id={text}/>
      </span>
      {showDivider && <Divider type={'vertical'} style={{margin: '0 4px 0 16px', backgroundColor: '#B9B9B9'}}/>}
    </div>
  ))
  const renderTabContent = (tab, idx) => {


    // console.log("renderTabContent", tab, idx)
    return (
      <div style={{
        flex: 1,
        backgroundColor: '#fff',
        height: '100%',
        borderRadius: 8,
      }}>
        <div style={{
          padding: '24px',
          borderBottom: '1px solid #DCDCDC',
        }}>
          <div
            style={{
              height: '24px',
              display: 'flex',
              flexDirection: 'row',
            }}>
            <AgentStatusComponent icon={<PcOn/>} text={'common.pc.on'} showDivider={true}/>
            <AgentStatusComponent icon={<PcOff/>} text={'common.pc.off'} showDivider={true}/>
            <AgentStatusComponent icon={<VlinkLive/>} text={'common.vlink.live'} showDivider={false}/>

            {/*탭 버튼*/}
            <div style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              flex: 1,
              width: "100%",
              justifyContent: 'right',
            }}>

              {/*TODO: 20250102 - 다음 패치에 적용*/}

              {/*<AtomicButton*/}
              {/*  style={{*/}
              {/*    margin: '4px',*/}
              {/*    top: activeType === 'card' ? '4px' : undefined,*/}
              {/*    padding: '16px',*/}
              {/*  }}*/}
              {/*  // style={{*/}
              {/*  //   padding: activeType === 'card' ? '28px 24px 20px 24px' : '24px',*/}
              {/*  // }}*/}
              {/*  type="text"*/}
              {/*  icon={activeType === 'card' ? <CardViewActive/> : <CardViewInactive/>}*/}
              {/*  onClick={() => {*/}
              {/*    setActiveType('card')*/}
              {/*  }}*/}
              {/*/>*/}
              {/*<AtomicButton*/}
              {/*  style={{*/}
              {/*    margin: '4px',*/}
              {/*    top: activeType === 'layout' ? '4px' : undefined,*/}
              {/*    padding: '16px',*/}
              {/*  }}*/}
              {/*  // style={{*/}
              {/*  //   padding: activeType === 'layout' ? '28px 24px 20px 24px' : '24px',*/}
              {/*  // }}*/}
              {/*  type="text"*/}
              {/*  icon={activeType === 'layout' ? <LayoutViewActive/> : <LayoutViewInactive/>}*/}
              {/*  onClick={() => {*/}
              {/*    setActiveType('layout')*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
          </div>
        </div>
        <div style={{
          padding: 20,
        }}
             onClick={handleClickAgent}
        >
          {activeType === 'card' ?
            <AgentCardView tab={tab} tabIndex={idx} onClickConnect={handleClickConnect}
                           onClickSetting={handleClickSetting} onClickAgentItem={handleClickAgent}/> :
            <AgentLayoutView tab={tab} tabIndex={idx} onClickConnect={handleClickConnect}
                           onClickSetting={handleClickSetting} onClickAgentItem={handleClickAgent}/>
          }
        </div>
      </div>
    )
  }


  const menuOptions = (item) => (
    <StyledMenuOptions
      options={[
        {
          icon: <Edit/>,
          label: <IntlMessages id="home.pc.edit"/>,
          key: "edit",
        },
        {
          icon: <Delete/>,
          label: <IntlMessages id="home.pc.delete"/>,
          key: "delete",
        }
      ]}
      onClick={(option) => {
        // console.log('option', item, option)

        props.onClickSetting && props.onClickSetting(item, option.key);
        if (option.key === "delete") {
          setActiveKey(0);
        }
      }}
    />
  );


  const tabItems = useMemo(() => {
    if (tabs && tabs.length) {
      return [...tabs, ...[{name: ADD_LAYOUT_NAME}]].reduce((acc, cur, idx) => {
        acc.push({
          ...cur,
          label: <div style={{
            padding: '8px 12px',
            backgroundColor: activeKey === idx ? '#FFFFFF' : 'transparent',
            borderTopLeftRadius: idx === 0 ? 6 : 0,
          }}
          >
            {
              activeKey === idx ? <div style={{
                position: 'absolute',
                width: '100%',
                height: 6,
                top: 0,
                left: 0,
                // backgroundColor: colorPrimary,
                backgroundImage: 'linear-gradient(#DF043A 50%, #FFFFFF 0%)',
                borderTopLeftRadius: idx === 0 ? 6 : 0,
              }}
              /> : null}
            {
              cur.name === ADD_LAYOUT_NAME ?
                <div style={{
                  position: 'relative',
                  left: '-4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <Plus/>
                </div> :
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <StyledTooltip content={menuOptions(cur)}
                                 open={visibleTooltip.hasOwnProperty(idx)}
                                 onOpenChange={(e) => setVisibleTooltip(e)}
                  >
                    <span style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <span style={{
                        fontSize: '20px',
                        color: activeKey === idx ? colorPrimary : '#7E7E7E',
                        // fontWeight: activeKey === idx ? 700: 400,
                      }}>{cur.name}</span>
                      {idx === 0 ?
                        <ArrowDown color={activeKey === idx ? colorPrimary : '#B9B9B9'}/>

                        :
                        <ArrowDown color={activeKey === idx ? colorPrimary : '#B9B9B9'}/>
                      }
                      {activeKey !== idx && activeKey - 1 !== idx && idx !== props.tabs.length - 1 ?
                        <div style={{
                          position: 'absolute',
                          right: '0px',
                          height: '20px',
                          width: '1px',
                          backgroundColor: '#B9B9B9',
                        }}/> : undefined}
                    </span>
                  </StyledTooltip>
                </div>

            }
          </div>,
          key: idx,
          children: renderTabContent(cur, idx),
        })
        return acc;
      }, [])
    } else {
      return []
    }
  }, [tabs, activeKey, visibleTooltip, activeType]);

  const renderTabBar = (props, DefaultTabBar) => {
    // console.log('renderTabBar', props)
    return (
      <StickyBox
        offsetTop={64}
        offsetBottom={20}
        style={{
          zIndex: 1,
        }}
      >
        <DefaultTabBar
          {...props}
          style={{
            background: '#DCDCDC',
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
          }}
        />
      </StickyBox>
    );
  }

  const handleClickAdd = (e) => {
    props.onClickAdd && props.onClickAdd();
  }

  // console.log('tabItems', tabItems)

  return (
    <Tabs
      style={{
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
      }}
      className={'agent-tabs'}
      activeKey={activeKey}
      animated={false}
      renderTabBar={renderTabBar}
      items={tabItems}
      onTabClick={(key, e) => {

        if (key === tabItems.length - 1) {
          // TODO: + 아이콘
          handleClickAdd();
          setVisibleTooltip({});
        } else if (key === activeKey) {
          // console.log("=>onTabClick toggle", key, visibleTooltip)
          // console.log("onTabClick toggle", visibleTooltip.hasOwnProperty(key))

          if (visibleTooltip.hasOwnProperty(key)) {
            setVisibleTooltip({})
          } else {
            handlePressTab(key)
          }
        } else { //탭이 변경된 경우
          setActiveKey(key);
          setVisibleTooltip({});
          props.onTabChange && props.onTabChange(key, e)

        }
      }}
      indicator={{
        size: 0,
      }}
    />
  )
}


export default AgentTabs;

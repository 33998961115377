import React, {memo, useState} from 'react';
import OutlineButton from "../../../../../components/OutlineButton";
import IntlMessages from "../../../../../util/IntlMessages";
import PcOn from "../../../../../assets/svg/PcOn";
import PcOff from "../../../../../assets/svg/PcOff";
import StyledTooltip from "../../../../../components/StyledTooltip";
import Settings from "../../../../../assets/svg/Settings";
import StyledMenuOptions from "../../../../../components/StyledMenuOptions";
import Edit from "../../../../../assets/svg/Edit";
import Delete from "../../../../../assets/svg/Delete";
import {Typography} from "antd";
import VlinkLive from "../../../../../assets/svg/VlinkLive";

const {Text} = Typography;

const Box = ({
               box,
               index,
               gridSize,
               boxHeight,
               boxWidth,
               isDragging,
               style,
               onPress,
               onPressSetting,
               handleMouseDown,
               onClickConnect,
               onClickSetting,
             }) => {
  const [visibleTooltip, setVisibleTooltip] = useState(false)

  const menuOptions = (
    <StyledMenuOptions
      options={[
        {
          icon: <Edit/>,
          label: <IntlMessages id="home.pc.edit"/>,
          key: "edit",
        },
        {
          icon: <Delete/>,
          label: <IntlMessages id="home.pc.delete"/>,
          key: "delete",
        }
      ]}
      onClick={(option) => {
        console.log('option', box, option)
        setVisibleTooltip(false);
        onClickSetting && onClickSetting(box, option)
      }}
    />
  );

  const GearIcon = memo(({x, y}) => (
    <div style={{
      position: 'absolute',
      cursor: 'pointer',
      right: 12,
      top: 12,
      // pointerEvents: 'all',
    }}
         onClick={(e) => {
           console.log('Settings')
           // onClickSetting(e, item)
         }}
    >
      <StyledTooltip
        content={menuOptions}
        open={visibleTooltip}
        onOpenChange={() => {
          setVisibleTooltip(!visibleTooltip);
        }}>
        <div>
          <Settings/>
          {/* SVG 안에서 클릭이 잘 안됨. 그래서 랩핑씌워 놓았음 */}
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}>

          </div>
        </div>
      </StyledTooltip>
    </div>
  ));

  const ConnectButtonComponent = memo(({agent, disabled}) => (
    <div style={{
      position: 'absolute',
      right: 12,
      bottom: 12,
      pointerEvents: 'all',
    }}>
      <OutlineButton
        label={<IntlMessages id="vlink.common.shortConnect"/>}
        onClick={() => {
          onClickConnect && onClickConnect(agent)
          console.log('Connect button clicked!');
        }}
      />
    </div>
  ));

  const AgentStatusComponent = memo(({agent}) => (
    <div style={{
      position: 'absolute',
      left: 12,
      bottom: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 8,
    }}>
      {agent.status === 'pcOn' ? <PcOn/> : agent.status === 'live' ? <VlinkLive /> : <PcOff/>}
    </div>
  ))

  const RemotePcName = ({name}) => (
    <div style={{
      position: 'absolute',
      left: 12,
      top: 12,
    }}>
      <Text
        style={{
          fontSize: 20,
          color: '#181818',
        }}
      >
        {name}
      </Text>

    </div>
  )

  return (
    <g
      onMouseDown={(e) => {
        handleMouseDown(e, index)
      }}
      onTouchStart={(e) => handleMouseDown(e, index)}
      // onContextMenu={(e) => e.preventDefault()} // 오른쪽 클릭 메뉴 방지
      style={{cursor: 'grab'}}
    >
      <rect
        x={box.x}
        y={box.y}
        width={boxWidth}
        height={boxHeight}
        fill="#ffffff"
        stroke="#DCDCDC"
        rx={5}
        ry={5}
        style={{...style}}
      />
      <foreignObject
        x={box.x}
        y={box.y}
        width={boxWidth}
        height={boxHeight}
      >
        <RemotePcName name={box.custom_name}/>
        <AgentStatusComponent agent={box}/>
        <ConnectButtonComponent agent={box}/>
        <GearIcon/>
      </foreignObject>
    </g>
  );
};

export default Box;

import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DataChannel from "../../containers/WebRTC/DataChannel";
import {useNavigate} from "react-router-dom";
import Connecting from "./Connecting";
import Connected from "./Connected";
import {appCustomName, sendDC} from 'src/util/common';

import {updateAgentSelected, updatePermissionResult, updateSwitchApp} from "../../appRedux/actions";
import {usePopup} from "../../util/use-popup";
import {useIntl} from "react-intl";

const Stream = () => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const dcRef = useRef(null);
  const refSwitchAgent = useRef(false)

  const agentSelected = useSelector(({cs}) => cs.agentSelected);
  const agents = useSelector(({cs}) => cs.agents);
  const activeAgents = useSelector(({cs}) => cs.activeAgents);
  const deviceAccessToken = useSelector(({cs}) => cs.deviceAccessToken);
  const publicIP = useSelector(({cs}) => cs.publicIP);
  const subscribeDataChannels = useSelector(({webrtc}) => webrtc.subscribeDataChannels);
  const permissionResult = useSelector(({webrtc}) => webrtc.permissionResult);
  const switchApp = useSelector(({webrtc}) => webrtc.switchApp);
  const navigate = useNavigate()

  const [dc, setDc] = useState(undefined)
  const [dat, setDat] = useState(undefined)
  const [streamURL, setStreamUrl] = useState(false);
  const [pcList, setPcList] = useState([]);
  const {infoPopup} = usePopup();

  useEffect(() => {
    // console.log('SWS-DEBUG-STREAM-001')

    return () => {
      // console.log('SWS-DEBUG-STREAM-002', dcRef.current)
      dispatch(updateAgentSelected(undefined))
      dispatch(updateSwitchApp(undefined))
      dispatch(updatePermissionResult({}))

      // sendDC(dcRef.current, {
      //   command: 'close_stream',
      // })
      dcRef.current && dcRef.current.close();
    }
  }, []);

  useEffect(() => {
    // console.log('agentSelected', agentSelected)
    if (agentSelected) {
      if (dat !== agentSelected.device_access_token) {
        // 이미 원격 중인데, 다른 에이전트로 전환한다. -> 홈화면으로이동하지않기
        if (dat) {
          refSwitchAgent.current = true
        }
        // sendDC(dcRef.current, {
        //   command: 'close_stream',
        // })
        dcRef.current && dcRef.current.close();
        setDc(undefined)
      }
      setTimeout(() => {
        setDat(agentSelected.device_access_token)
      }, 10)
    } else {
      setDat(undefined)
      setTimeout(() => {
        setStreamUrl(false);
      }, 10)
    }
  }, [agentSelected]);

  useEffect(() => {

    if (switchApp) {
      dispatch(updateAgentSelected({
        ...agentSelected,
        app: switchApp,
      }))
      sendDC(dcRef.current, {
        command: 'switch',
        handle: switchApp.handle,
      })
    }
  }, [switchApp]);

  useEffect(() => {
    // console.log('subscribeDataChannels', subscribeDataChannels)
    if (agentSelected && subscribeDataChannels.hasOwnProperty(agentSelected.device_access_token)) {
      setDc(subscribeDataChannels[agentSelected.device_access_token])
    } else {
      setDc(undefined)
      setStreamUrl(false);
    }
  }, [subscribeDataChannels]);

  useEffect(() => {
    if (dc && agentSelected) {
      dcRef.current = dc
      // console.log('DEBUG-113', dc, deviceAccessToken, agentSelected.app, publicIP)
      sendDC(dcRef.current, {
        data: {
          category: 'ws',
          service: "GetPermission",
          opponent_dat: deviceAccessToken,
          channel_type: 'stream',
          handle: agentSelected.app.handle,
          ip: publicIP,
        },
      })
    }
  }, [dc]);

  useEffect(() => {
    if (permissionResult.service === 'AccessDenied' ||
      permissionResult.service === 'SessionTimeout' ||
      permissionResult.service === 'AppClosed' ||
      permissionResult.service === 'AppMinimized') {
      infoPopup(intl.formatMessage({id: permissionResult.reason.message}), () => {
        navigate(`/home`)
      })

    } else if (permissionResult.service === 'AccessAuthorized') {
      agentSelected && sendDC(dcRef.current, {
        data: {
          category: 'ws',
          service: 'RequestToOfferSC',
          opponent_dat: deviceAccessToken,
          token: permissionResult.token,
          channel_type: 'stream',
          handle: agentSelected.app.handle,
        },
      });
    }
  }, [permissionResult]);

  useEffect(() => {
    if (agents) {
      const newData = []
      agents.map((item, idx) => {
        newData.push({
          ...item,
          id: idx,
        })
      })

      setPcList(newData.reduce((acc, cur, idx) => {
        let s = 'pcOff';
        const findActive = activeAgents && activeAgents.hasOwnProperty(cur.device_access_token) && activeAgents[cur.device_access_token];
        if (findActive) {
          for (let i = 0; i < findActive.apps.length; i++) {
            s = 'pcOn'

            if (activeAgents[cur.device_access_token].agent_connections?.length > 0) {
              for (let j = 0; j < activeAgents[cur.device_access_token].agent_connections.length; j++) {
                if (findActive.apps[i].handle === activeAgents[cur.device_access_token].agent_connections[j].handle) {
                  s = 'live'
                  break
                }
              }
            }

            acc.push({
              ...cur,
              ...findActive.apps[i],
              status: s,
            })
          }
        }
        return acc
      }, []))
    }

  }, [agents, activeAgents]);


  // console.log("====>STREAM", dc, streamURL)

  const handleQuickConnect = (newInfo) => {
    // console.log("quick connect ", JSON.stringify(newInfo))
    //agentInfo => 현재접속정보
    //data => 변경할 접속정보

    //동일한 PC인 경우
    if (agentSelected.device_access_token === newInfo.device_access_token) {
      //다른 앱을 선택했으면
      if (agentSelected.app.handle !== newInfo.handle) {
        const newAgent = {
          ...agentSelected,
          app: {
            title: newInfo.title,
            handle: newInfo.handle,
            product: appCustomName(newInfo.title),
          }
        }
        dispatch(updateAgentSelected(newAgent))
        sendDC(dcRef.current, {
          command: 'switch',
          handle: newInfo.handle,
        })
      }
    } else {
      const findAgent = agents.find((item) => item.device_access_token === newInfo.device_access_token);
      const newAgent = {
        ...findAgent,
        app: {
          title: newInfo.title,
          handle: newInfo.handle,
          product: appCustomName(newInfo.title),
        }
      }
      dispatch(updateAgentSelected(newAgent))

    }

  }

  if (!agentSelected) {
    navigate(`/home`);
    return null
  }
  // console.log('SWS-DEBUG-STREAM-002', "DataChannel")

  return <Fragment>
    {agentSelected && dat === agentSelected.device_access_token ?
      <Fragment>
        <DataChannel
          agentIndex={0}
          agent={agentSelected}
          onClose={() => {
            console.log('SWS-DEBUG-onClose', window.location.href, refSwitchAgent.current)
            // Important: Do not fix stream url
            // - 스트리밍 중인 경우에만 Home 으로 이동
            if (!refSwitchAgent.current) {
              if (window.location.href?.includes('home/stream')) {
                // window.history.back()
                navigate(`/home`);
              }
              refSwitchAgent.current = false
            }
          }}
          onOpenSC={url => {
            setTimeout(() => {
              setStreamUrl(url);
            }, 500)
          }}
        />
        <Connected pcList={pcList} agentInfo={agentSelected} onQuickConnect={handleQuickConnect}/>
      </Fragment> : null}
    {!(dc && streamURL) && <Connecting
      dcConnected={dc}
      onCancel={() => navigate(`/home`)}
    />}
  </Fragment>
}


export default Stream;

import React, { useState } from 'react';
import { FloatButton } from 'antd';
import AddAgent from "../../../../../assets/svg/AddAgent";
import { useIntl } from "react-intl";
import './index.css';

const FloatingButton = ({
                          baseTop = 95,
                          baseRight = 50,
                          baseBottom = 0,
                          baseLeft = -45,
                          width,
                          height,
                          onClickConnect,
                        }) => {
  const intl = useIntl();
  const [position, setPosition] = useState({ top: baseTop + 20, right: baseRight + 20 });

  const handleMouseDown = (e) => {
    e.preventDefault();
    const startX = e.clientX;
    const startY = e.clientY;
    handleDragStart(startX, startY);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    const startX = e.touches[0].clientX;
    const startY = e.touches[0].clientY;
    handleDragStart(startX, startY);
  };

  const handleDragStart = (startX, startY) => {
    const initialTop = parseInt(position.top, 10);
    const initialLeft = parseInt(position.left, 10);
    const initialBottom = parseInt(position.bottom, 10);
    const initialRight = parseInt(position.right, 10);

    const handleMouseMove = (moveEvent) => {
      const currentX = moveEvent.type === 'mousemove' ? moveEvent.clientX : moveEvent.touches[0].clientX;
      const currentY = moveEvent.type === 'mousemove' ? moveEvent.clientY : moveEvent.touches[0].clientY;
      const deltaX = currentX - startX;
      const deltaY = currentY - startY;
      const threshold = 50;

      // console.log(width, height);
      if (Math.abs(deltaX) > threshold || Math.abs(deltaY) > threshold) {
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          setPosition({
            top: initialTop,
            right: deltaX > 0 ? baseRight + 20 : width + baseLeft,
          });
        } else {
          setPosition({
            top: deltaY > 0 ? height + baseBottom : baseTop + 20,
            right: initialRight,
          });
        }
      }
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchend', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchmove', handleMouseMove);
    window.addEventListener('touchend', handleMouseUp);
  };

  return (
    <FloatButton.Group
      shape="square"
      style={{
        position: 'absolute',
        height: '70px',
        transition: 'all 0.3s ease',
        top: position.top,
        left: position.left,
        right: position.right,
        bottom: position.bottom,
      }}
    >
      <FloatButton
        className={'fmb'}
        icon={<AddAgent />}
        style={{
          width: '70px',
          height: '70px',
          fontSize: '16px',
        }}
        description={intl.formatMessage({ id: 'home.pc.add' })}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onClick={() => {
          onClickConnect && onClickConnect()
        }}
      />
    </FloatButton.Group>
  );
};

export default FloatingButton;

import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import CustomCanvasV2 from "../../components/CustomCanvasV2";
import Cursor from "../../components/Cursor";
import Prev from "assets/svg/Prev";
import {useMeasure} from "react-use";
import {useNavigate} from "react-router-dom";
import StyledTooltip from "../../components/StyledTooltip";
import ArrowDown from "../../assets/svg/ArrowDown";
import FavoriteYes from "../../assets/svg/FavoriteYes";
import FavoriteNo from "../../assets/svg/FavoriteNo";
import Pencil from "../../assets/svg/Pencil";
import Eraser from "../../assets/svg/Eraser";
import LaserPointer from "../../assets/svg/LaserPointer";
import {appCustomName} from 'src/util/common';

import beApis from "../../util/apis/be";
import './index.css'
import {CardItem} from '../Home/SideCard/CardItem';

const Connected = ({agentInfo, pcList, onQuickConnect}) => {

  const navigate = useNavigate();
  const resolution = useSelector(({webrtc}) => webrtc.resolution);
  const csSelected = useSelector(({cs}) => cs.csSelected)

  const [containerRef, {width, height}] = useMeasure();

  const [mode, setMode] = useState({pencil: false, eraser: false, pointer: false});
  const [laserOn, setLaserOn] = useState(false);
  const [enableDraw, setEnableDraw] = useState(false);
  const [initDraw, setInitDraw] = useState(false);
  const [drawType, setDrawType] = useState(0);
  const parentRef = useRef(null);
  const [color, setColor] = useState("#db082f");
  const [w, setW] = useState(width)
  const [h, setH] = useState(height)

  const [favorites, setFavorites] = useState([]);

  const [isFavorite, setIsFavorite] = useState(false);

  const [openQuickConnect, setOpenQuickConnect] = useState(false);

  useEffect(() => {
    if (resolution.hasOwnProperty('width') && resolution.hasOwnProperty('height')) {
      const appRatio = resolution.height / resolution.width;
      const webRatio = height / width;

      // console.log('resolution', appRatio, webRatio, height, width)

      if (appRatio < webRatio) {
        setW(width)
        setH(height * (appRatio / webRatio))
      } else {
        setW(width * (webRatio / appRatio))
        setH(height)
      }
    }
  }, [resolution, width, height]);

  useEffect(() => {
    if (mode.pencil || mode.eraser) {
      setInitDraw(true);
      setEnableDraw(true);
    } else {
      setEnableDraw(false);
    }
    setOpenQuickConnect(false);
  }, [mode])

  useEffect(() => {
    setEnableDraw(false);
    setMode({})
    setInitDraw(false);
  }, [agentInfo])

  useEffect(() => {
    if (enableDraw) {
      setInitDraw(true);
    }
  }, [enableDraw])

  const gotoPrev = () => {
    navigate(`/home`);
  }

  useEffect(() => {

    beApis.GetFavorites(csSelected.host).then(resp => {
      setFavorites(resp.favorites);
    })
  }, [])

  useEffect(() => {
    const find = favorites.find(item => item.device_access_token === agentInfo.device_access_token && item.product === agentInfo.app.title)
    if (find) {
      setIsFavorite(true)
    } else {
      setIsFavorite(false)
    }
  }, [favorites, agentInfo])

  const handleAddFavorite = () => {
    setOpenQuickConnect(false);

    const matched = favorites.find(item => item.device_access_token === agentInfo.device_access_token && item.product === agentInfo.app.title)
    const newFavorites = []
    if (matched) {
      favorites.map(item => {
        if (item.device_access_token === agentInfo.device_access_token && item.product === agentInfo.app.title) {

        } else {
          newFavorites.push(item)
        }
      })
    } else {
      favorites.map(item => {
        newFavorites.push(item)
      })
      newFavorites.push({
        device_access_token: agentInfo.device_access_token,
        product: agentInfo.app.title,
      })
    }
    const values = {
      favorites: newFavorites,
    }
    beApis.UpdateFavorites(csSelected.host, values).then(resp => {
      beApis.GetFavorites(csSelected.host).then(resp => {
        setFavorites(resp.favorites);
      })
    })
  }

  const handleQuickConnect = (info) => {
    setOpenQuickConnect(false);
    onQuickConnect(info);
  }


  const menuOptions = pcList.map((item, idx) => {
    const newItem = {
      ...item,
      name: item.custom_name,
      product: appCustomName(item.title),
      active: true,
      group_name: item.groups && item.groups.length > 0 ? item.groups[0].name : "",
    }
    return <div style={{width: 250}}>
      <CardItem showBtn={false} data={newItem} onClick={handleQuickConnect} key={"pcList" + idx}/>
    </div>
  })


  const ellipsisStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    lineHeight: "47px",
    position: "relative",
    width: "unset",
    maxWidth: 120,
  };

  return (
    <div style={{
      flex: 1,
      background: '#4f4f4f',
      width: "100vw",
      height: "100vh",
      position: "absolute",
      top: 0,
      left: 0,
      // padding: 20,

    }}>
      <div className="stream-header">
        {/* 이전 버튼 */}
        <div style={{flex: 1, display: "flex", flexDirection: "row", alignItems: "center"}}>
          <div
            style={{
              marginLeft: 4,
              marginRight: 16,
              display: "flex",
              width: 58,
              height: 58,
              alignItems: 'center',
              justifyContent: 'center',
              cursor: "pointer",
            }}
            onClick={gotoPrev}
          >
            <Prev style={{display: 'flex'}}/>
          </div>
          <div
            style={{
              display: "flex",
              width: 58,
              height: 58,
              alignItems: 'center',
              justifyContent: 'center',
              cursor: "pointer",
              backgroundColor: mode.pencil ? "#FFE9EF" : "#fff"
            }}
            onClick={() => {
              if (mode.pencil)
                setMode({})
              else {
                setMode({pencil: true})
              }
            }}
          >
            <Pencil style={{display: 'flex', transform: "scale(0.8)",}} color={mode.pencil ? "#DF043A" : ""}/>
          </div>
          <div
            style={{
              display: "flex",
              width: 58,
              height: 58,
              alignItems: 'center',
              justifyContent: 'center',
              cursor: "pointer",
              backgroundColor: mode.eraser ? "#FFE9EF" : "#fff"
            }}
            onClick={() => {
              if (mode.eraser)
                setMode({})
              else {
                setMode({eraser: true})
              }

            }}
          >
            <Eraser style={{display: 'flex', transform: "scale(0.8)",}} color={mode.eraser ? "#DF043A" : ""}/>
          </div>
          <div
            style={{
              display: "flex",
              width: 58,
              height: 58,
              alignItems: 'center',
              justifyContent: 'center',
              cursor: "pointer",
              backgroundColor: mode.pointer ? "#FFE9EF" : "#fff"
            }}
            onClick={() => {
              if (mode.pointer)
                setMode({})
              else {
                setMode({pointer: true})
              }

            }}
          >
            <LaserPointer style={{display: 'flex', transform: "scale(0.8)",}} color={mode.pointer ? "#DF043A" : ""}/>
          </div>
        </div>


        <StyledTooltip content={menuOptions} open={openQuickConnect}>
          <div style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{margin: 10, height: "100%", display: "flex", cursor: "pointer"}} onClick={handleAddFavorite}>
              {isFavorite ? <FavoriteYes/> : <FavoriteNo/>}
            </div>
            <div
              style={{display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", fontSize: 20}}
              onClick={() => setOpenQuickConnect(!openQuickConnect)}>
              <div style={ellipsisStyle}>{agentInfo.custom_name}</div>
              <span style={{margin: 3}}>/</span>
              <div style={ellipsisStyle}>{appCustomName(agentInfo.app.title)}</div>
            </div>
            <div style={{margin: 10, height: "100%", transform: "scale(0.8)", display: "flex"}}
                 onClick={() => setOpenQuickConnect(!openQuickConnect)}>
              <ArrowDown style={{cursor: "pointer"}}/>
            </div>
          </div>
        </StyledTooltip>
        <div style={{flex: 1}}>
          <span></span>
        </div>
      </div>

      <div style={{
        height: "calc(100% - 60px)",
        background: '#080101',
        overflow: "hidden",
      }}
           ref={containerRef}
      >
        <div className={'stream-contents' + (mode.pointer ? " cursor-none" : "")} ref={parentRef} id={'viewer-base'}>

          {mode.pointer && <Cursor parentRef={parentRef}/>}
          <div className="video-wrapper">
            <video
              playsInline
              autoPlay
              className={'app-stream-video'}
              id='app-video'
              width={w}
              height={h}
            />
          </div>
          <div style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            display: enableDraw ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",

          }}>
            {initDraw && <CustomCanvasV2 type={mode} color={"#f00"} width={w} height={h}/>}
          </div>
        </div>
      </div>
    </div>

  )
}


export default Connected;

import React, { memo, useEffect, useRef, useState } from 'react';
import { Card, List, Popover, Typography } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import OutlineButton from "../../../components/OutlineButton";

import PcOn from "../../../assets/svg/PcOn";
import PcOff from "../../../assets/svg/PcOff";
import ArrowRight from 'src/assets/svg/ArrowRight';

import "./CardItem.css";
import StyledTooltip from 'src/components/StyledTooltip';
import VlinkLive from "../../../assets/svg/VlinkLive";


export const CardItem = ({ data, onClick, showBtn=true }) => {
  const ellipsisRef = useRef();

  const [showTooltip, setShowTooltip] = useState(false);

  const ConnectButtonComponent = memo(({ agent, style, disabled }) => (
    <div style={{...style}}>
      <OutlineButton
        // disabled={disabled}
        label={<IntlMessages id="vlink.common.shortConnect" />}
        onClick={() => {
          onClick && onClick(agent)
        }}
      />
    </div>
  ))

  const AgentStatusComponent = memo(({ agent }) => (
    <div style={{
      padding: 8, display: "flex", alignItems: "center"
    }}>
      {agent.status === 'pcOn' ? <PcOn /> : agent.status === 'live' ? <VlinkLive /> : <PcOff />}
    </div>
  ))

  const ArrowRightComponent = memo(() => (
    <div style={{
      display: "inline-flex", alignItems: "center"
    }}>
      <ArrowRight />
    </div>))

  const ellipsisStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    lineHeight: "47px",
    position: "relative",
    width: "unset",
  };

  const handleBodyClick = () => {
    if (showBtn) {
      return
    }
    else {
      onClick(data);
    }
  }

  useEffect(() =>{
    // console.log("ellipsisRef check", data)
    // console.log(" ellipsisRef.scrollWidth , ellipsisRef.clientWidth", ellipsisRef.current?.scrollWidth , ellipsisRef.current?.clientWidth)

    if (ellipsisRef.current) {
      if (ellipsisRef.current.scrollWidth > ellipsisRef.current?.clientWidth ) {
        setShowTooltip(true)
      }
      else {
        setShowTooltip(false)
      }

    }

  }, [ellipsisRef])


  return <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
    <div style={{ position: "relative", flex: 1, maxWidth: data.status && showBtn? "calc(100% - 65px)": "100%", height: 47, display: "flex", cursor: showBtn? "": "pointer" }}
      onClick={handleBodyClick}
    >
      <AgentStatusComponent agent={data} />
      <StyledTooltip content={showTooltip? (data.group_name? (data.group_name + " > "): "") + data.name + " > " + data.product : ""} placement="bottom" trigger="hover" >
        <div style={{ overflow: "hidden"}}>
          <div style={ellipsisStyle} ref={ellipsisRef}>
            {/* <div style={{lineHeight: "47px"}}> */}
            <span>{data.group_name}</span>
            {data.group_name && <ArrowRightComponent />}
            {/* <span style={{color: "#B9B8B9"}}>{" > "}</span> */}
            <span>{data.name}</span>
            <ArrowRightComponent />
            <span>{data.product}</span>
            {/* </div> */}
          </div>
        </div>
      </StyledTooltip>
    </div>
    {data.status !== "pcOff" && showBtn && <ConnectButtonComponent style={{width: "60px"}} agent={data}/>}
  </div>
}

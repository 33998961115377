import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={2.5} y={2.5} width={15} height={15} rx={0.5} stroke="#4F4F4F" />
    <path d="M10 7V13.0001" stroke="#4F4F4F" strokeLinecap="round" />
    <path d="M13 10H6.99994" stroke="#4F4F4F" strokeLinecap="round" />
  </svg>
);
export default SVGComponent;

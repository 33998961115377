import {Card, List} from "antd";
import CircularProgress3 from "../../../components/CircularProgress3";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import AgentCardItem from "./AgentCardItem";
import AgentCardAddItem from "./AgentCardAddItem";


const AgentCardView = (props) => {

  const [data, setData] = useState([])
  const agents = useSelector(({ cs }) => cs.agents);
  const activeAgents = useSelector(({ cs }) => cs.activeAgents);

  const [visibleTooltip, setVisibleTooltip] = useState({})


  useEffect(() => {
    if (agents) {
      // console.log('AgentCardView', props.tab, props.tabIndex, agents, activeAgents)
      const newData = [];
      setVisibleTooltip({});

      if (props.tabIndex === 0) { //전체
        agents.map((item, idx) => {
          newData.push({
            ...item,
            id: idx,
          })
        })
      } else {
        agents.map((item, idx) => {

          // console.log('AgentCardView make item', item)

          let exist = false;
          for (let i = 0; i < item.groups.length; i++) {
            // console.log('AgentCardView make newData', item.groups[i], props.tab.name)
            if (item.groups[i].name === props.tab.name) {
              exist = true;
              break;
            }
          }
          if (exist) {
            newData.push({
              ...item,
              id: idx,
            })
          }
        })

        // console.log('AgentCardView newData', newData)
        newData.push({ id: -999, addCard: true})
      }

      setData(newData.reduce((acc, cur, idx) => {
        let s = 'pcOff'
        if (activeAgents && activeAgents.hasOwnProperty(cur.device_access_token) && activeAgents[cur.device_access_token]) {
          if (activeAgents[cur.device_access_token].agent_connections?.length > 0) {
            s = 'live'
          } else {
            s = 'pcOn'
          }
        }
        acc.push({
          ...cur,
          status: s,
        })
        return acc
      }, []))
    }
  }, [props.tab, agents, activeAgents]);

  const handleItemSelect = (e, item, idx) => {
    e.stopPropagation();

    if (visibleTooltip.hasOwnProperty(idx)) {
      setVisibleTooltip({});
    } else {
      setVisibleTooltip({
        [idx]: true,
      })
    }

    props.onClickAgentItem();
  }

  const handleClickAdd = (arg1, arg2, args3) => {
    setVisibleTooltip({});
    props.onClickConnect(arg1, arg2, args3);
  }

  const handleClick = () => {
    setVisibleTooltip({});
  }

  return (
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 4,
        }}
        locale={{
          emptyText: <span>{''}</span>
        }}
        dataSource={data}
        renderItem={(item, idx) => {
          if (item.id > -1) {
            return <AgentCardItem
              item={item}
              tab={props.tab}
              tabIndex={props.tabIndex}
              open={visibleTooltip.hasOwnProperty(idx)}
              onOpenChange={(e) => setVisibleTooltip(e)}
              onClick={handleClick}
              onClickConnect={props.onClickConnect}
              onClickSetting={(e, item) => handleItemSelect(e, item, idx)}
              onClickSettingMenu={props.onClickSetting}/>
          } else {
            return <AgentCardAddItem tab={props.tab} onClick={handleClickAdd} />
          }
        }}
      />
  )
}

export default AgentCardView;

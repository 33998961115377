import React, {Fragment, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import Grid from "./Test/Grid";


const AgentLayoutView = (props) => {

  const [data, setData] = useState([])
  const agents = useSelector(({ cs }) => cs.agents);
  const activeAgents = useSelector(({ cs }) => cs.activeAgents);
  const divRef = useRef(null);

  const [visibleTooltip, setVisibleTooltip] = useState({})
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const GRID_SIZE = 20

  useEffect(() => {
  }, []);

  useEffect(() => {
    // 컴포넌트 마운트 시 초기 크기 설정
    updateDimensions();

    // resize 이벤트 리스너 추가
    window.addEventListener('resize', updateDimensions);

    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        console.log('resize', width, height);
        setDimensions({ width, height });
      }
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    // 클린업: 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', updateDimensions);
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (agents) {
      // console.log('AgentLayoutView', props.tab, props.tabIndex, agents, activeAgents)
      const newData = [];
      setVisibleTooltip({});

      if (props.tabIndex === 0) { //전체
        agents.map((item, idx) => {
          newData.push({
            ...item,
            id: idx,
          })
        })
      } else {
        agents.map((item, idx) => {

          // console.log('AgentLayoutView make item', item)

          let exist = false;
          for (let i = 0; i < item.groups.length; i++) {
            // console.log('AgentLayoutView make newData', item.groups[i], props.tab.name)
            if (item.groups[i].name === props.tab.name) {
              exist = true;
              break;
            }
          }
          if (exist) {
            newData.push({
              ...item,
              id: idx,
            })
          }
        })

        // console.log('AgentLayoutView newData', newData)
        // newData.push({ id: -999, addCard: true})
      }

      setData(newData.reduce((acc, cur, idx) => {
        let s = 'pcOff'
        if (activeAgents && activeAgents.hasOwnProperty(cur.device_access_token) && activeAgents[cur.device_access_token]) {
          if (activeAgents[cur.device_access_token].agent_connections?.length > 0) {
            s = 'live'
          } else {
            s = 'pcOn'
          }
        }
        acc.push({
          ...cur,
          status: s,
        })
        return acc
      }, []))
    }
  }, [props.tab, agents, activeAgents]);

  const updateDimensions = () => {
    if (divRef.current) {
      const { width, height } = divRef.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  };

  const handleItemSelect = (e, item, idx) => {
    e.stopPropagation();

    if (visibleTooltip.hasOwnProperty(idx)) {
      setVisibleTooltip({});
    } else {
      setVisibleTooltip({
        [idx]: true,
      })
    }

    props.onClickAgentItem();
  }

  const handleClickAdd = (arg1, arg2, args3) => {
    setVisibleTooltip({});
    props.onClickConnect(arg1, arg2, args3);
  }

  const handleClick = () => {
    setVisibleTooltip({});
  }

  console.log('agents', dimensions.width, dimensions.height)

  return (
      <div
        ref={divRef}
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
      }}

      >
        <Grid
          key={`${dimensions.width}-${dimensions.height}`} // key 속성 추가
          groupName={props.tab.name}
          tab={props.tab}
          agents={data}
          viewBoxHeight={Number(GRID_SIZE * (dimensions.width / 1000) * 60)}
          viewBoxWidth={dimensions.width}
          width={dimensions.width}
          height={dimensions.height}
          gridSize={Number(GRID_SIZE * (dimensions.width / 1000))}
          onClickConnect={props.onClickConnect}
          onClickSetting={props.onClickSetting}
          // onClickSettingMenu={props.onClickAgentItem}
        />
      </div>
  )
}

export default AgentLayoutView;

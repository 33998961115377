import React, {memo, useMemo} from 'react';
import {Card, List, Typography} from "antd";
import './index.css';
import OutlineButton from "../../../components/OutlineButton";
import IntlMessages from "../../../util/IntlMessages";
import PcOn from "../../../assets/svg/PcOn";
import PcOff from "../../../assets/svg/PcOff";
import Settings from "../../../assets/svg/Settings";
import StyledTooltip from "../../../components/StyledTooltip";
import StyledMenuOptions from "../../../components/StyledMenuOptions";
import Edit from "../../../assets/svg/Edit";
import Delete from "../../../assets/svg/Delete";
import VlinkLive from "../../../assets/svg/VlinkLive";

const {Text} = Typography;

const AgentCardItem = (props) => {

  const {item, tab, tabIndex, onClick, onClickConnect, onClickSetting, onClickSettingMenu} = props

  const ConnectButtonComponent = memo(({agent, disabled}) => (
    <div style={{
      position: 'absolute',
      right: 12,
      bottom: 12,
    }}>
      <OutlineButton
        label={<IntlMessages id="vlink.common.shortConnect"/>}
        onClick={() => {
          onClickConnect && onClickConnect(agent, 'connect', tab)
        }}
      />
    </div>
  ))

  const AgentStatusComponent = memo(({agent}) => (
    <div style={{
      position: 'absolute',
      left: 12,
      bottom: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 8,
    }}>
      {agent.status === 'pcOn' ? <PcOn/> : agent.status === 'live' ? <VlinkLive /> : <PcOff/>}
    </div>
  ))

  const options = props.tabIndex === 0? [
    {
      icon: <Edit/>,
      label: <IntlMessages id="home.pc.edit"/>,
      key: "edit",
    }]:[
      {
        icon: <Edit/>,
        label: <IntlMessages id="home.pc.edit"/>,
        key: "edit",
      },
      {
        icon: <Delete/>,
        label: <IntlMessages id="home.pc.delete"/>,
        key: "delete",
      }
    ]

  const menuOptions = (
    <StyledMenuOptions
      options={options}
      onClick={(option) => {
        // console.log('option', item, option)
        onClickSettingMenu && onClickSettingMenu(item, option)

      }}
    />
  );

  const MoreComponent = () => (
    <div style={{
      position: 'absolute',
      cursor: 'pointer',
      right: 12,
      top: 12,
    }}
    onClick={(e) => onClickSetting(e, item) }
    >

      <StyledTooltip content={menuOptions} open={props.open} onOpenChange={props.onOpenChange}>
        <Settings/>
      </StyledTooltip>
      {/*<a data-tooltip-id="my-tooltip">*/}
      {/*  <Settings />*/}
      {/*</a>*/}
      {/*<Tooltip*/}
      {/*  id={"my-tooltip"}*/}
      {/*  border="1px solid #B9B9B9"*/}
      {/*  place={'bottom'}*/}
      {/*  openOnClick*/}
      {/*  closeEvents={{*/}
      {/*    click: true,*/}
      {/*  }}*/}
      {/*  globalCloseEvents={{*/}
      {/*    escape: true,*/}
      {/*    clickOutsideAnchor: true,*/}
      {/*    scroll: true,*/}
      {/*    resize: true,*/}
      {/*  }}*/}
      {/*  style={{*/}
      {/*    backgroundColor: '#FFFFFF',*/}
      {/*    color: '#4F4F4F',*/}
      {/*  }}*/}
      {/*  // content={menuOptions}*/}
      {/*  // trigger="click">*/}
      {/*>*/}
      {/*  {menuOptions}*/}
      {/*</Tooltip>*/}
    </div>
  )

  return (
    <List.Item className={'agent-card-item'}>
      <Card
        hoverable
        bordered
        style={{
          // border: "1px solid #DCDCDC",
          borderRadius: 6,
          // borderColor: '#DCDCDC',
          height: 136,
          // margin: 5,
          // padding: 10,
          backgroundColor: '#FFFFFF',
        }}
        onClick={onClick}
        >
        <div style={{
          flex: 1,
        }}>
          <Text
            style={{
              fontSize: 20,
              color: '#181818',
              paddingRight: 32,
            }}
          >
            {item.custom_name}
          </Text>
          <AgentStatusComponent agent={item}/>
          <ConnectButtonComponent agent={item} disabled={item.status === "pcOff"}/>
          <MoreComponent/>
        </div>
      </Card>
    </List.Item>
  );
};

export default AgentCardItem;

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input, List, Button } from "antd";
import { useIntl } from "react-intl";
import dayjs from 'dayjs';

import {
  updateActiveAgents,
  updateAgents,
  updateAgentSelected,
  updateDeviceAccessToken,
  updateForceLoading,
  updateLayouts,
  updatePublicIP,
  updateSubUserList,
  updateWsSend
} from "src/appRedux/actions";

import { useAuth } from "src/authentication";
import AtomicPopup from "src/components/AtomicPopup";
import CircularProgress3 from "src/components/CircularProgress3";
import beApis from "src/util/apis/be";
import IntlMessages from "src/util/IntlMessages";

import AgentAppItem from "./AgentAppItem";
import AgentTabs from "./AgentTabs";
import AgentItem from './AgentItem';
import SideCard from './SideCard';

const Home = () => {

  const { authUser, subUser, clearUserFcmToken , isLoadingUser} = useAuth();

  const intl = useIntl();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const wsConnected = useSelector(({ ws }) => ws.wsConnected);
  const wsReceive = useSelector(({ ws }) => ws.wsReceive);
  const csSelected = useSelector(({ cs }) => cs.csSelected)
  const deviceAccessToken = useSelector(({ cs }) => cs.deviceAccessToken)
  const agents = useSelector(({ cs }) => cs.agents)
  const activeAgents = useSelector(({ cs }) => cs.activeAgents)
  const agentSelected = useSelector(({ cs }) => cs.agentSelected)
  const layouts = useSelector(({ cs }) => cs.layouts)
  const forceLoading = useSelector(({ common }) => common.forceLoading)
  const agentViewType = useSelector(({common}) => common.agentViewType)

  const prevWs = useRef(undefined);
  const myLayoutRef = useRef(undefined)
  const editedTabRef = useRef({});
  const editedAgentRef = useRef({});

  // 실시간 에이전트 정보
  const agentRef = useRef({
    // [device access token] : value
  })

  const agentsRef = useRef({});

  const agentSelectedRef = useRef(undefined)

  const [refresh, setRefresh] = useState(true)
  const [openSelectAppPopup, setOpenSelectAppPopup] = useState(false);
  const [openAddGroupPopup, setOpenAddGroupPopup] = useState(false);
  const [openAddAgentPopup, setOpenAddAgentPopup] = useState(false)
  const [openChangePcNamePopup, setOpenChangePcNamePopup] = useState(false)

  const [favorites, setFavorites] = useState([]);
  const [recents, setRecents] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [pcName, setPcName] = useState("");

  const [remoteAgents, setRemoteAgents] = useState([]);

  useEffect(() => {
    beApis.GetPublicIP(csSelected.host).then(resp => {
      console.log('Public IP:', resp)
      dispatch(updatePublicIP(resp))
    })

  }, []);


  useEffect(() => {
    // console.log("location", authUser, subUser)
    if (authUser && !subUser) {
      // navigate("")
      navigate('/user/select-user')
    }
  }, [location])


  useEffect(() => {

    if (prevWs.current !== wsReceive) {
      // console.log('wsReceive', wsReceive)
      if (wsReceive.service === 'ReadyToLive') {
        setRefresh(dayjs())
      }
      // } else if (wsReceive.service === 'RegisterComplete') {
      //   // processActiveAgents(wsReceive.device_access_token, wsReceive)
      // } else if (wsReceive.service === 'TellYou') {
      //   processActiveAgents(wsReceive.device_access_token, wsReceive)
      // } else if (wsReceive.service === 'Unregister') {
      //   processActiveAgents(wsReceive.device_access_token, undefined)
      // }
    }
    prevWs.current = wsReceive;

  }, [wsReceive]);

  useEffect(() => {

    agentRef.current = activeAgents;

    // console.log("activeAgents useEffect:", activeAgents)

    if (agentSelectedRef.current) {
      if (activeAgents.hasOwnProperty(agentSelectedRef.current.device_access_token)) {
        const agent = activeAgents[agentSelectedRef.current.device_access_token]
        if (agent) {
          updateAgentSelectedRef(agent)
        } else {
          // 혹시 팝업이 열려 있었다면?
          setOpenSelectAppPopup(false);
        }
      }
    }

  }, [activeAgents])

  // 서브 사용자가 로그인을 하면 웹소켓을 연결한 후 Register 한다.
  // useEffect(() => {
  //   if (!wsConnected) {
  //     dispatch(updateDeviceAccessToken(undefined))
  //     return
  //   }
  //   if (wsConnected && authUser && subUser && authUser.id === subUser.id) {
  //     let device_uuid = localStorage.getItem('device_uuid')
  //     if (!device_uuid || device_uuid.length === 0) {
  //       device_uuid = v4();
  //       localStorage.setItem('device_uuid', device_uuid)
  //     }
  //     dispatch(updateWsSend({
  //       data: {
  //         category: 'ws',
  //         service: 'Register',
  //         host_user: authUser.parent_username,
  //         email: authUser.username,
  //         client_type: 'web',
  //         mac_address: device_uuid,
  //         name: navigator.userAgent.toLowerCase(),
  //       },
  //     }))
  //   }
  // }, [authUser, subUser, wsConnected])

  useEffect(() => {
    // console.log('DEBUG-XX1', refresh, deviceAccessToken, forceLoading)

    if ((refresh !== false && deviceAccessToken) || forceLoading) {

      let clearCache = undefined
      if (refresh) {
        clearCache = 'true'
      }

      if (forceLoading?.hasOwnProperty('agents')) {

        beApis.GetAgents(csSelected.host, clearCache).then(resp => {
          agentsRef.current = JSON.parse(JSON.stringify(resp));
          setRefresh(false)
          dispatch(updateAgents(resp))
        })
      } else {

        beApis.GetAgents(csSelected.host, clearCache).then(resp => {
          agentsRef.current = JSON.parse(JSON.stringify(resp));
          setRefresh(false)
          dispatch(updateAgents(resp))
        })

        beApis.GetLayouts(csSelected.host, clearCache).then(resp => {
          setRefresh(false);
          myLayoutRef.current = resp
          // let newList = [];
          // const hasAll = resp.findIndex((item) => item.name === intl.formatMessage({ id: "home.all" }))
          // if (hasAll >= 0) {
          //   newList = resp;
          // }
          // else {
          //   newList = [...[{ name: intl.formatMessage({ id: "home.all" }) }], ...resp];
          // }
          dispatch(updateLayouts([...[{ name: intl.formatMessage({ id: "home.all" }) }], ...resp]));
        })

        beApis.GetSubUsers(csSelected.host, clearCache).then(resp => {
          setRefresh(false)
          dispatch(updateSubUserList(resp))
        })

        beApis.GetFavorites(csSelected.host, forceLoading?.hasOwnProperty('favorites')).then(resp => {
          setFavorites(resp.favorites);
        })

        beApis.GetRecentVisits(csSelected.host, forceLoading?.hasOwnProperty('recentVisits')).then(resp => {
          setRecents(resp.recent_visits);
        })

      }

      if (forceLoading) {
        dispatch(updateForceLoading(undefined))
      }
    }
  }, [deviceAccessToken, refresh, forceLoading]);

  // useEffect(() => {
  //   if (agents && agents.length) {
  //     dispatch(updateWsSend({
  //       data: {
  //         category: 'ws',
  //         service: 'TellMe',
  //       },
  //     }));
  //   }
  // }, [agents])

  const processActiveAgents = (dat, data) => {
    if (data) {
      const now = new Date().getTime()
      // 1. 업데이트
      agentRef.current[dat] = {
        ...data,
        updated_at: now,
      }
    } else {
      agentRef.current[dat] = undefined
    }
    // 2. 정리
    // Object.keys(agentRef.current).map(dat => {
    //   if (agentRef.current[dat] && now - agentRef.current[dat].timestamp > 60) {
    //     agentRef.current[dat] = undefined
    //   }
    // })
    // dispatch(updateActiveAgents({ ...agentRef.current }))
  }

  const updateAgentSelectedRef = (agent) => {
    // console.log('agentRef.current', agentRef.current)
    // console.log('->agent.device_access_token', agent.device_access_token)
    // console.log('->agentsRef.current',agentsRef.current)
    if (!agent) {
      agentSelectedRef.current = undefined
    } else {
      if (agentRef.current && agentRef.current.hasOwnProperty(agent.device_access_token) && agentsRef.current) {

        // console.log('agentSelectedRef.current: agent', agent)
        // console.log('agentSelectedRef.current: agentRef.current[agent.device_access_token]', agentRef.current[agent.device_access_token])
        // console.log('agentSelectedRef.current: agentSelectedRef', agentSelectedRef.current)


        //redux의 agents에서 agent를 찾아서 selectedRef에 넣어야함
        const currAgent = agentsRef.current.find((item) => item.device_access_token === agent.device_access_token)

        agentSelectedRef.current = {
          ...currAgent,
          ...agentRef.current[agent.device_access_token],
        }
        dispatch(updateAgentSelected(JSON.parse(JSON.stringify(agentSelectedRef.current))))
      }
      else {
        agentSelectedRef.current = {
          ...agent
        }
      }
    }
  }
  // agent 카드 이벤트: 접속 버튼
  const handleClickAgentConnect = (agent, mode, tab) => {
    // console.log('handleClick', agent, mode, tab)
    if (mode === 'add') { //원격지PC추가 버튼을 눌렀을때
      // console.log('add', tab)

      editedTabRef.current = { ...tab };
      setRemoteAgents(agentsRef.current.reduce((acc, cur, idx) => {
        // 20250102 - 이미 그룹에 속한 PC 들 제외
        // 현재 보고 있는 Group 에 없는 PC 들
        // if (cur.groups?.filter(item => item.name === tab.name).length === 0) {
        if (!cur.groups || cur.groups.length === 0) {
          acc.push(cur)
        }
        return acc
      }, []))
      setOpenAddAgentPopup(true);
    } else {
      updateAgentSelectedRef(agent);
      setOpenSelectAppPopup(true);
    }
  }

  //즐겨찾기나 최근방문의 접속은 여기서 app정보 채워준다
  const handleClickDirectConnect = (info) => {

    //agent정보 가져와야함
    const currAgent = agentsRef.current.find((item) => item.device_access_token === info.device_access_token)

    // console.log("handleClickDirectConnect", info)

    agentSelectedRef.current = {
      ...currAgent,
      ...agentSelectedRef.current,
      ...info
    }
    dispatch(updateAgentSelected(JSON.parse(JSON.stringify(agentSelectedRef.current))))

    const app = info.apps?.find((item) => item.title === info.product);

    if (app) {
      handleClickConnect(app);
    }
  }

  const handleClickConnect = (app) => {
    // console.log('handleClickConnect', agentSelectedRef.current, app)
    agentSelectedRef.current = {
      ...agentSelectedRef.current,
      app: app,
    }
    // console.log('DEBUG002', 'agentSelectedRef.current', agentSelectedRef.current)
    dispatch(updateAgentSelected(JSON.parse(JSON.stringify(agentSelectedRef.current))))
    setOpenSelectAppPopup(false)
    navigate(`/home/stream`);
  }

  const handleClickAdd = () => {
    setOpenAddGroupPopup('home.addLayout');
  }

  //탭 아이템의 화살표 눌렀을때
  const handleClickSetting = (editTab, mode) => {
    // console.log("handleClickSetting", editTab, mode)

    if (mode === "delete") {
      onPressSubmitUpdateLayout(mode, editTab.name);
    }
    else if (mode === "edit") {
      setOpenAddGroupPopup('home.editLayout');
      setGroupName(editTab.name)
      editedTabRef.current = { ...editTab };
    }
  }

  //원격지PC setting 버튼 눌렀을때
  const handleClickAgentSetting = (agent, option) => {
    // console.log("handleClickAgentSetting", agent, option, editedTabRef.current.name)
    if (option.key === "delete") {

      if (!agent.groups) {
        agent.groups = []
      }
      const newGroup = agent.groups.filter(item => item.name !== editedTabRef.current.name)

      const values = {
        device_access_token: agent.device_access_token,
        groups: newGroup,
      }
      beApis.UpdateAgents(csSelected.host, values).then(resp => {
        setRefresh(dayjs())
      })

    }
    else if (option.key === "edit") {
      setOpenChangePcNamePopup(true);
      setPcName(agent.custom_name)
      editedAgentRef.current = { ...agent };

    }
  }

  const onPressSubmitChangePcName = () => {
    // setAgents(agents.reduce((acc, cur, idx) => {
    //   if (cur.device_access_token === agent?.device_access_token) {
    //     acc.push({
    //       ...cur,
    //       custom_name: customPcName,
    //     })
    //   } else {
    //     acc.push(cur)
    //   }

    //   return acc
    // }, []))

    setOpenChangePcNamePopup(false);
    const agent = editedAgentRef.current;

    const values = {
      device_access_token: agent.device_access_token,
      name: pcName,
    }
    beApis.UpdateAgents(csSelected.host, values).then(resp => {
      setRefresh(dayjs())
    })
  }

  const handleClickAddGroup = (mode) => {

    setOpenAddGroupPopup(false);
    onPressSubmitUpdateLayout(mode);

  }

  const onPressSubmitUpdateLayout = (mode, name) => {

    let edited = undefined
    //
    // console.log("onPressSubmitUpdateLayout", mode, name, editedTabRef.current)

    if (mode === 'delete') {
      //layout에서 삭제
      myLayoutRef.current = myLayoutRef.current.filter(item => item.name !== name)
    } else {
      if (groupName.length <= 0) {
        return
      }

      if (!myLayoutRef.current) {
        myLayoutRef.current = []
      }

      if (mode === 'home.editLayout') {
        myLayoutRef.current.map((item, idx) => {
          if (item.name === editedTabRef.current.name) {
              myLayoutRef.current[idx].name = ''
          }
        })
      }

      let dupCheckName = groupName.trim()
      for (let i = 0; i < 10; i++) {
        const dupCount = myLayoutRef.current.filter(item => item.name === dupCheckName).length;
        if (dupCount > 0) {
          dupCheckName = `${groupName} (${dupCount + i + 1})`
        } else {
          break
        }
      }
      if (mode === 'home.editLayout') {
        edited = {
          from: editedTabRef.current.name,
          to: dupCheckName,
        }

        myLayoutRef.current.map((item, idx) => {
          if (item.name.length === 0) {
            // TAB 이 그대로 유지되려면
            myLayoutRef.current[idx].name = dupCheckName
            myLayoutRef.current[idx].from = editedTabRef.current.name
            myLayoutRef.current[idx].to = dupCheckName
          } else {
            myLayoutRef.current[idx].from = ''
            myLayoutRef.current[idx].to = ''
          }
        })
        // // 깜빡임 방지
        // setTimeout(() => {
        //   // This logic is for UX: 서버에 아직 업데이트되지는 않았지만 사용자에게 먼저 보여준다.
        //   // 클라이언트에서 미리 업데이트(반응속도가 빠르게 보이기 위한 착시)
        //   setAgents(agents.reduce((acc, cur, idx) => {
        //     for (let i = 0; i < cur.groups.length; i++) {
        //       if (cur.groups[i].name === editedTabRef.current.name) {
        //         cur.groups[i].name = dupCheckName
        //       }
        //     }
        //     acc.push(cur)
        //     return acc
        //   }, []))
        // }, 200)
      } else {
        myLayoutRef.current.push({name: dupCheckName})
      }
    }

    dispatch(updateLayouts([...[{ name: intl.formatMessage({ id: "home.all" }) }], ...myLayoutRef.current]));
    // console.log("onPressSubmitUpdateLayout", newLayout, edited)

    const values = {
      layouts: myLayoutRef.current,
      edited: edited,
    }
    beApis.UpdateLayout(csSelected.host, values).then(resp => {

      setRefresh(dayjs())
      setGroupName("");
      editedTabRef.current = {} //초기화
    })
  }

  const handleClickAddAgent = (agent) => {
    // console.log("handleClickAddAgent", agent, editedTabRef.current.name);

    if (!agent.groups) {
      agent.groups = []
    }
    const newGroup = agent.groups.concat([{
      name: editedTabRef.current.name,
      x: 0,
      y: 0,
    }])

    setOpenAddAgentPopup(undefined)


    const values = {
      device_access_token: agent.device_access_token,
      groups: newGroup,
    }
    beApis.UpdateAgents(csSelected.host, values).then(resp => {
      setRefresh(dayjs())
    })
  }

  // 연결이 안되면 Home 화면은 로딩 중 표시.
  if (!wsConnected || isLoadingUser) {
    return <CircularProgress3 />
  }

  const handleTabChange = (tab) => {
    editedTabRef.current = {...layouts[tab]};
  }

  // 연결되었음.
  return (
    <div style={{
      height: "100%",
      display: "flex",
      flexDirection: "row",
      padding: 40,
    }}>
      <div style={{
        height: '100%',
        // width: '100%',
        maxWidth: agentViewType === 'card' ? undefined : '1080px',
        backgroundColor: '#fff',
        borderRadius: 8,
        flex: 1,
      }}>
        <AgentTabs tabs={layouts}
          onClickConnect={handleClickAgentConnect}
          onClickAdd={handleClickAdd}
          onClickSetting={handleClickSetting}
          onClickAgentSetting={handleClickAgentSetting}
          onTabChange={handleTabChange}
          />


        {/* 탭에 그룹추가/수정 팝업 */}

        <AtomicPopup
          title={openAddGroupPopup === 'home.addLayout' ? <IntlMessages id="home.addLayout" /> : <IntlMessages id="home.editLayout" />}
          open={openAddGroupPopup}
          onOk={() => {
            // TODO: 20240530
            //  엔터키 눌러서 Ok 될 수 있게 처리
            // handleClickAddGroup(openAddGroupPopup)
            setOpenAddGroupPopup(false)
          }}
          onCancel={() => {
            setOpenAddGroupPopup(false)
          }}
          centered
          footer={<div style={{
            height: 24,
          }}></div>}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 24 }}>
            {/* TODO: 20240530 - auto focus input */}
            <Input
              style={{ height: 46, marginBottom: 24 }}
              onChange={(e) => setGroupName(e.target.value)}
              value={groupName}
              onPressEnter={() => {
                handleClickAddGroup(openAddGroupPopup);
              }}
            />
            <Button type="primary" className="gx-mb-0"
              disabled={groupName.length === 0}
              style={{
                height: 46,
                width: '100px',
                borderRadius: 4,
                fontSize: 18,
                fontWeight: 700,
              }}
              danger
              onClick={() => handleClickAddGroup(openAddGroupPopup)}
            >
              {openAddGroupPopup === 'home.addLayout' ? <IntlMessages id="vlink.common.add" /> : <IntlMessages id="vlink.common.update" />}
            </Button>
          </div>
        </AtomicPopup>

        {/* 원격지 PC 추가 선택 팝업 */}
        <AtomicPopup
          title={<IntlMessages id="home.pc.add.selectNewPC" />}
          open={openAddAgentPopup}
          onOk={() => {
            setOpenAddAgentPopup(false)
          }}
          onCancel={() => {
            setOpenAddAgentPopup(false)
          }}
          centered
          footer={<div style={{
            height: 24,
          }}></div>}
        // width={1300}
        >

          <List
            locale={{
              emptyText: <span>{''}</span>
            }}
            dataSource={remoteAgents}
            renderItem={(item) => <AgentItem item={item} onClick={handleClickAddAgent} />}
          />
        </AtomicPopup>


        {/* PC이름수정 팝업 */}

        <AtomicPopup
          title={<IntlMessages id="home.changePCName" />}
          open={openChangePcNamePopup}
          onOk={() => {
            setOpenChangePcNamePopup(false)
          }}
          onCancel={() => {
            setOpenChangePcNamePopup(false)
          }}
          centered
          footer={<div style={{
            height: 24,
          }}></div>}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 24 }}>
            <Input
              style={{ height: 46, marginBottom: 24 }}
              onChange={(e) => setPcName(e.target.value)}
              value={pcName}
              onPressEnter={onPressSubmitChangePcName}
            />
            <Button type="primary" className="gx-mb-0"
              disabled={pcName.length === 0}
              style={{
                height: 46,
                width: '100px',
                borderRadius: 4,
                fontSize: 18,
                fontWeight: 700,
              }}
              danger
              onClick={onPressSubmitChangePcName}
            >
              <IntlMessages id="vlink.common.update" />
            </Button>
          </div>
        </AtomicPopup>

        {/* agent접속 선택팝업 */}
        <AtomicPopup
          title={
            <span>
              <span>{`${agentSelectedRef.current?.custom_name}`}</span>
              <span>{' '}</span>
              <span><IntlMessages id="home.select.app" />
              </span>
            </span>
          }
          open={openSelectAppPopup}
          onOk={() => {
            setOpenSelectAppPopup(false)
          }}
          onCancel={() => {
            setOpenSelectAppPopup(false)
          }}
          centered
          footer={<div style={{
            height: 24,
          }}></div>}
        // width={1300}
        >

          <List
            locale={{
              emptyText: <span>{''}</span>
            }}
            dataSource={agentSelected?.apps}
            renderItem={(item) => <AgentAppItem item={item} onClick={handleClickConnect} />}
          />
        </AtomicPopup>
      </div>
      <div style={{
        // width: "400px",
        height: "100%",
        marginLeft: 20,
        display: "flex",
        flexDirection: "column",
        flex: 0.5,
      }}>
        <SideCard items={favorites} title={<IntlMessages id="vlink.common.gnb.favorite" />} style={{ height: "calc(50% - 10px)", marginBottom: 20 }} activeAgents={activeAgents} onClick={handleClickDirectConnect} />
        <SideCard items={recents} title={<IntlMessages id="vlink.common.gnb.recentlyVisit" />} style={{ height: "calc(50% - 10px)" }} activeAgents={activeAgents} onClick={handleClickDirectConnect} />
      </div>
    </div>
  )
}


export default Home;
